<template>
  <CModal id="StockImportOrder" class="ActionModel" size="xl" :show.sync="Toggle" :title="$t('Global.Add') + $t('Product/Stock.ImportOrder')" :centered="true" @update:show="HandleHideModal()">
    <CRow>
      <CCol md="8">
        <CInput
          :label="$t('Product/Stock.ImportOrderNum')"
          v-model="Data.OrderNum"
          :placeholder="$t('Product/Stock.ImportOrderNumDescription')"
          :disabled="true"
          horizontal
        />
        <CInput
          class="d-none"
          :label="$t('Product/Stock.VendorOrderNum')"
          v-model="Data.Variable.VendorOrderNum"
          :placeholder="$t('Product/Stock.VendorOrderNumDescription')"
          horizontal
        />
        <CTextarea :label="$t('Product/Stock.Note')" v-model="Data.Note" rows="2" horizontal />
      </CCol>
      <CCol md="4">
        <CRow>
          <CCol tag="label" sm="3" class="col-form-label" for="CreateTime">
            {{ $t('Product/Stock.CreateTime') }}
          </CCol>
          <CCol sm="9">
            <v-date-picker v-model="Data.CreateTime" mode="date" is24hr>
              <template v-slot="{ inputValue, inputEvents }">
                <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents" :disabled="Data.Status.Order === 'Y'">
                  <template #append-content>
                    <CIcon name="cil-calendar"/>
                  </template>
                </CInput>
              </template>
            </v-date-picker>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <div class="font-weight-bold mb-3">
      <label>{{ $t('Product/Stock.ChooseItems') }}</label>
      <multiselect
        id="SelectSKU"
        v-model="SelectSKU"
        label="Name_SKU"
        track-by="SKU"
        :tag-placeholder="$t('Product/Stock.EnterToAdd')"
        :placeholder="$t('Product/Stock.EnterToAdd')"
        :deselectLabel="$t('Global.DeselectLabel')"
        :selectedLabel="$t('Global.SelectedLabel')"
        :options="SKUList"
        :multiple="false"
        :taggable="false"
        :showNoResults="false"
        :showNoOptions="false"
        :disabled="Data.Status.Order === 'Y'"
        @select="AddItems"
        @input="() => SelectSKU = null"
      >
        <template #noOptions>
          {{ $t('Global.NoOptions') }}
        </template>
      </multiselect>
    </div>
    <CDataTable
      :loading="Loading"
      id="SelectItemList"
      :items="Data.ProductInfo"
      :fields="ProductInfoField"
      :noItemsView="{
        noResults: $t('Global.NoResults'),
        noItems: $t('Global.NoItems')
      }"
      responsive
      hover
    >
      <template #No="{index}">
        <td>
          {{ index + 1 }}
        </td>
      </template>
      <template #SKU="{item}">
        <td>
          {{ item.CustomSeriesNum }}
        </td>
      </template>
      <template #Amount="{item}">
        <td>
          <CInput
            :prepend="$store.state.currency + '$'"
            v-model="item.Amount.Actual"
            :disabled="Data.Status.Order === 'Y'"
            type="number"
            size="sm"
          />
        </td>
      </template>
      <template #Quantity="{item}">
        <td>
          <CInput
            v-model="item.ExpectedQuantity"
            :disabled="Data.Status.Order === 'Y'"
            type="number"
            size="sm"
          />
        </td>
      </template>
      <template #Note="{item}">
        <td>
          <CInput
            v-model="item.Note"
            :disabled="Data.Status.Order === 'Y'"
            type="text"
            size="sm"
          />
        </td>
      </template>
      <template #Action="{index}">
        <td>
          <CButton v-if="Action === 'Add' || Data.Status.Order === 'Y'" color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveItems(index)">
            <CIcon name="cil-trash" class="c-icon-custom-size" />
          </CButton>
        </td>
      </template>
    </CDataTable>
    <template #footer-wrapper>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#StockImportOrder' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
      <footer class="modal-footer border-top-0">
        <template v-if="Data.Status.Order !== 'Y'">
          <CButton id="ChooseProduct" @click="Save(false)" color="info" :disabled="Data.ProductInfo.length === 0 || Data.Status.Order === 'Y'">
            {{ $t('Product/Stock.Save') }}
          </CButton>
          <CButton id="ChooseProductPut" @click="Save(true)" color="success" :disabled="Data.ProductInfo.length === 0 || Data.Status.Order === 'Y'">
            {{ $t('Product/Stock.SavePut') }}
          </CButton>
        </template>
        <CButton id="Finish" v-else color="danger" :disabled="true">
          {{ $t('Product/Stock.Finished') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Toggle', 'CurrentOrderID'],
  name: 'StockImportOrder',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data() {
    return {
      Loading: false,
      Submit: false,
      Action: 'Add',
      SearchLoading: false,
      SearchAccountData: [],
      SKUList: [],
      SelectSKU: null,
      Data: {
        CreateTime: this.$dayjs().toISOString(),
        MemberInfo: {},
        Variable: {},
        ProductInfo: [],
        Status: {}
      },
      VendorOptions: [
        {
          label: '',
          text: ''
        }
      ],
      StoreOptions: [
        {
          label: '',
          text: ''
        }
      ]
    }
  },
  computed: {
    ProductInfoField () {
      return [
        { key: 'No', label: 'No' },
        { key: 'SKU', label: this.$t('Product/Stock.SKU') },
        { key: 'Name', label: this.$t('Product/Stock.SKUName') },
        { key: 'Amount', label: this.$t('Product/Stock.Amount') },
        { key: 'Quantity', label: this.$t('Product/Stock.Quantity') },
        { key: 'Note', label: this.$t('Product/Stock.Note') },
        { key: 'Action', label: '' }
      ]
    },
    TotalAmount () {
      return this.Data.ProductInfo.map(item => (item.Amount.Actual || 0) * (item.ExpectedQuantity || 0)).reduce((a, c) => a + c, 0)
    }
  },
  mounted() {
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const Execute = [
        this.GetSKUList()
      ]
      if (this.CurrentOrderID) {
        this.Action = 'Edit'
        Execute.push(
          this.GetOrder()
        )
      }
      return Promise.all(Execute).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetOrder() {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/data',
        method: 'post',
        data: {
          URL: '/order/detail',
          Data: {
            ID: this.CurrentOrderID
          }
        }
      }).then(({ data }) => {
        this.Data = data
      }).catch((error) => {
        throw error
      })
    },
    GetSKUList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/data',
        method: 'post',
        data: {
          URL: '/product/availableList',
          Data: {
            Taxonomy: 'Stock'
          }
        }
      }).then(({ data }) => {
        this.SKUList = data.map(item => {
          item.Name_SKU = `${item.Name} (SKU: ${item.CustomSeriesNum})`
          return item
        })
      }).catch((error) => {
        throw error
      })
    },
    AddItems (selectedOption) {
      this.Data.ProductInfo.push({
        _id: selectedOption._id,
        Taxonomy: selectedOption.Taxonomy || 'Stock',
        HeaderText: selectedOption.HeaderText,
        Stock: {
          ...selectedOption.Stock,
          SKU: selectedOption.CustomSeriesNum
        },
        Amount: selectedOption.Amount || {},
        EnableOption: 0,
        EnableStock: 1,
        SeriesNum: selectedOption.SeriesNum,
        CustomSeriesNum: selectedOption.CustomSeriesNum,
        Name: selectedOption.Name,
        Brand: selectedOption.Brand,
        Cover: selectedOption.Cover,
        Tags: selectedOption.Tags,
        Categories: selectedOption.Categories,
        OptionField: selectedOption.OptionField,
        ExpectedQuantity: 0,
        Quantity: 0,
        Discount: 1,
        Variable: selectedOption.Variable
      })
    },
    RemoveItems (index) {
      this.Data.ProductInfo.splice(index, 1)
    },
    Save(put = false) {
      this.Submit = true
      let Action
      if (this.Action === 'Edit') {
        Action = this.$store.dispatch('InnerRequest', {
          url: '/service/action',
          method: 'post',
          data: {
            URL: '/order/edit',
            Data: {
              ID: this.Data._id,
              UpdateData: {
                Note: this.Data.Note,
                Amount: this.TotalAmount,
                RealAmount: this.TotalAmount,
                TotalAmount: this.TotalAmount,
                CreateTime: this.Data.CreateTime,
                ProductInfo: this.Data.ProductInfo
              }
            }
          }
        })
      } else {
        Action = this.$store.dispatch('InnerRequest', {
          url: '/service/action',
          method: 'post',
          data: {
            URL: '/order/adminCreate',
            Data: {
              ...this.Data,
              Taxonomy: 'Import',
              OrderNumPrefix: '74',
              UserID: this.$store.state.user.uid,
              VisitID: '0',
              Amount: this.TotalAmount,
              RealAmount: this.TotalAmount,
              TotalAmount: this.TotalAmount,
              MemberInfo: {
                Name: this.$store.state.user.data.displayName,
                UserID: this.$store.state.user.uid,
                Email: this.$store.state.user.data.email
              },
              Variable: {
                _v: 0
              }
            }
          }
        })
      }
      return Action.then(({ data }) => {
        this.HandleHideModal()
        if (put === true) {
          return this.$store.dispatch('InnerRequest', {
            url: '/service/action',
            method: 'post',
            data: {
              URL: '/order/edit',
              Data: {
                ID: this.Action === 'Edit' ? this.Data._id : data.Data._id,
                UpdateData: {
                  'Status.Order': 'Y',
                  ProductInfo: this.Data.ProductInfo.map((item) => {
                    item.Quantity = item.ExpectedQuantity * -1
                    return item
                  })
                }
              }
            }
          }).then(() => {
            this.$emit('CreateOrder')
            this.Submit = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Product/SuccessAddImportOrderAndImportItem'),
              type: 'success'
            })
          })
        } else {
          this.Submit = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Product/SuccessAddImportOrder'),
            type: 'success'
          })
          this.$emit('CreateOrder')
        }
      }).catch((error) => {
        console.log(error)
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
      this.$emit('update:CurrentOrderID', null)
    }
  }
}
</script>

